@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600&display=swap);

@layer base {
    /* html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    } */
    body {
        @apply font-[Poppins];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}